import React from 'react';
import PropTypes from 'prop-types';
import Hotjar from 'nordic/hotjar';
import Page from 'nordic/page';
import { CrossSite } from 'op-frontend-commons';
import HeadView from '../../components/HeadView';

const CrossSiteView = (props) => {
  const {
    siteId,
    mlNavigationConfigItems,
    melidata,
    canonicalUrl = '',
  } = props;

  return (
    <Page name="crosssite" state={props} melidata={melidata}>
      <Hotjar id={3157827} triggers={['crosssite']} />

      <HeadView
        siteId={siteId}
        mlNavigationConfigItems={mlNavigationConfigItems}
        linkCanonical={canonicalUrl}
      />

      <CrossSite
        applicationName="Partner Central"
        countries={[
          {
            href: 'https://centrodepartners.mercadolivre.com.br/',
            name: 'Brasil',
          },
          {
            href: 'https://centrodepartners.mercadolibre.cl/',
            name: 'Chile',
          },
          {
            href: 'https://centrodepartners.mercadolibre.com.co/',
            name: 'Colombia',
          },
          {
            href: 'https://centrodepartners.mercadolibre.com.ar/',
            name: 'Argentina',
          },
          {
            href: 'https://centrodepartners.mercadolibre.com.mx/',
            name: 'México',
          },
          {
            href: 'https://centrodepartners.mercadolibre.com.uy/',
            name: 'Uruguay',
          },
          {
            href: 'https://centrodepartners.mercadolibre.com.pe/',
            name: 'Perú',
          },
        ]}
      />
    </Page>
  );
};

CrossSiteView.propTypes = {
  canonicalUrl: PropTypes.string,
};

export default CrossSiteView;
